<template>
  <div v-if="results && results.management_potential && results.management_potential.potentials" class="row">
    <div class="col-md-8">
      <div class="d-flex align-center justify-space-between mt-4 mt-md-8">
        <h3 class="page-title">{{ management_potential.title }}</h3>
      </div>
      <div class="compet-title-desc">{{ management_potential.description }}</div>
      <template v-if="allItems && allItems.length">
        <div class="compet-titles">
          <div class="col-6 pa-0">Характеристика</div>
          <div class="col-6 compet-title-2">Степень предрасположенности</div>
        </div>
        <PersonalAbilityRow v-for="(item,i) in allItems" :key="i" :item="item" />
      </template>
      <template v-else-if="topItems && topItems.length">
        <div class="compet-row__title">ВЫСОКОРАЗВИТЫЕ</div>
        <div class="compet-titles">
          <div class="col-6 pa-0">Характеристика</div>
          <div class="col-6 compet-title-2">Степень предрасположенности</div>
        </div>
        <PersonalAbilityRow v-for="(item,i) in topItems" :key="i" :item="item" />

        <div class="compet-row__title">НИЗКОРАЗВИТЫЕ</div>
        <div class="compet-titles">
          <div class="col-6 pa-0">Характеристика</div>
          <div class="col-6 compet-title-2">Степень предрасположенности</div>
        </div>
        <PersonalAbilityRow v-for="(item,i) in bottomItems" :key="i" :item="item" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonalAbilityRow from './helpers/PersonalAbilityRow';

export default {
  name: 'ManagementPotential',
  components: {
    PersonalAbilityRow
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    management_potential() {
        /* TODO management_potential */
       return this.results.management_potential ? this.results.management_potential : { title: '', description: '', potentials: [] };
    },
    sortedItems() {
      const arr = this.management_potential?.potentials?.length ? [...this.management_potential.potentials] : [];
      return arr.sort((a, b) => b.level - a.level);
    },
    allItems() {
      return !this.shortReport || this.sortedItems.length <= 10 ? this.sortedItems : null;
    },
    topItems() {
      return this.shortReport && this.sortedItems.length > 10 ? this.sortedItems.slice(0, 5) : null;
    },
    bottomItems() {
      return this.shortReport && this.sortedItems.length > 10 ? this.sortedItems.slice(-5) : null;
    },
  },
  methods: {
  },
};
</script>

<style>
.compet-titles {
    display: flex;
    font-weight: 600;
    margin: 20px 0;
}

.compet-title-desc {
    margin: 20px 0;
}
</style>
