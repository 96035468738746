<template>
  <div v-if="results && teamRoles && teamRoles.length">
    <div class="row mt-2 mt-md-8">
      <div class="col-12 pb-0">
        <h3 class="page-title">Роли в команде</h3>
      </div>

      <div class="col-md-6 py-0">
        <RadarChart :key="radarKey" :data="chartdata" />
      </div>

      <div class="col-md-5 offset-md-1">
        <div class="role-results">
          <div class="role-results__item page-description" v-for="role in teamRoles" :key="role.id">
            <span class="font-weight-600">{{role.title}}</span>
            <span>{{role.points}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-8">
      <div class="col-12">
        <div class="d-flex align-center justify-space-between mt-4 mt-md-8">
          <h3 class="page-title">Характеристики каждой роли</h3>
          <span class="pointer page-description" @click="toggleAllShow">
          {{listsIsOpen ? 'Скрыть все' : 'Раскрыть все'}}
        </span>
        </div>
      </div>

      <div class="col-md-5 py-0">
        <v-expansion-panels v-model="show1" multiple>
          <v-expansion-panel class="accordion"
                             v-for="(item,i) in characteristics1" :key="i">
            <v-expansion-panel-header>
              <div class="d-flex align-center">
                <svg class="mr-3 icon-sm" width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.734 5.77607C11.1414 5.30811 11.8684 5.30811 12.2759 5.77607L17.3088 11.5565C17.6438 11.9413 17.6438 12.5142 17.3088 12.899L12.2759 18.6794C11.8684 19.1474 11.1414 19.1474 10.734 18.6794L5.70113 12.899C5.3661 12.5142 5.3661 11.9413 5.70112 11.5565L10.734 5.77607Z" fill="var(--v-primary-base)"/>
                  <path d="M10.8841 5.61634C10.8265 5.65962 10.7736 5.70886 10.7264 5.76325L5.6937 11.5565C5.35942 11.9413 5.35971 12.5136 5.69438 12.898L10.7574 18.7144C10.8717 18.8457 11.0194 18.9437 11.1848 18.998C11.2489 19.019 11.315 19.0333 11.382 19.0406L11.4611 19.0492C11.482 19.0492 11.499 19.0322 11.499 19.0113V18.5503V12.2276V5.95788L11.4999 5.43892C11.5 5.42196 11.4862 5.4082 11.4693 5.4082L11.3692 5.41899C11.2828 5.42831 11.198 5.44947 11.1173 5.48186C11.0401 5.51287 10.9672 5.55391 10.9006 5.60391L10.8841 5.61634Z" fill="var(--color-rombus)"/>
                </svg>
                <span class="text-primary font-weight-600">{{item.title}}</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{item.description}}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div class="col-md-5 offset-md-2 py-0">
        <v-expansion-panels v-model="show2" multiple>
          <v-expansion-panel class="accordion"
                             v-for="(item,i) in characteristics2" :key="i">
            <v-expansion-panel-header>
              <div class="d-flex align-center">
                <svg class="mr-3 icon-sm" width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.734 5.77607C11.1414 5.30811 11.8684 5.30811 12.2759 5.77607L17.3088 11.5565C17.6438 11.9413 17.6438 12.5142 17.3088 12.899L12.2759 18.6794C11.8684 19.1474 11.1414 19.1474 10.734 18.6794L5.70113 12.899C5.3661 12.5142 5.3661 11.9413 5.70112 11.5565L10.734 5.77607Z" fill="var(--v-primary-base)"/>
                  <path d="M10.8841 5.61634C10.8265 5.65962 10.7736 5.70886 10.7264 5.76325L5.6937 11.5565C5.35942 11.9413 5.35971 12.5136 5.69438 12.898L10.7574 18.7144C10.8717 18.8457 11.0194 18.9437 11.1848 18.998C11.2489 19.019 11.315 19.0333 11.382 19.0406L11.4611 19.0492C11.482 19.0492 11.499 19.0322 11.499 19.0113V18.5503V12.2276V5.95788L11.4999 5.43892C11.5 5.42196 11.4862 5.4082 11.4693 5.4082L11.3692 5.41899C11.2828 5.42831 11.198 5.44947 11.1173 5.48186C11.0401 5.51287 10.9672 5.55391 10.9006 5.60391L10.8841 5.61634Z" fill="var(--color-rombus)"/>
                </svg>
                <span class="text-primary font-weight-600">{{item.title}}</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{item.description}}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import RadarChart from '@/components/ui/RadarChart';
import { mapGetters } from 'vuex';
import { throttle } from 'lodash';

export default {
  name: 'TeamRoles',
  components: {
    RadarChart,
  },
  data: () => ({
    radarKey: 0,
    show1: [],
    show2: [],
  }),
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    teamRoles() {
      const roles = this.results.team_roles_normalized?.team_roles?.team_roles;
      if (roles) {
        return [...roles].sort((a, b) => b.points - a.points);
      }
      return roles;
    },
    chartdata() {
      return {
        labels: this.teamRoles.map((r) => r.title),
        datasets: [
          {
            label: 'Результат',
            backgroundColor: this.$store.getters['auth/isTA'] ? 'rgba(65,195,44, 0.5)' : 'rgba(24,160,251, 0.7)',
            pointBackgroundColor: this.$store.getters['auth/isTA'] ? '#299119' : 'rgba(24,160,251)',
            pointRadius: 5,
            data: this.generateDate(),
          },
        ],
      };
    },
    characteristics1() {
      const count = Math.ceil(this.teamRoles.length / 2);
      return this.teamRoles.slice(0, count);
    },
    characteristics2() {
      const count = Math.ceil(this.teamRoles.length / 2);
      return this.teamRoles.slice(count);
    },
    listsIsOpen() {
      return this.show1.length || this.show2.length;
    },
  },
  mounted() {
    window.addEventListener('resize', this.radarKeyIncrement);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.radarKeyIncrement);
  },
  methods: {
    radarKeyIncrement: throttle(function () {
      this.radarKey += 1;
    }, 1000),
    generateDate() {
      const result = [];
      this.teamRoles.forEach((role) => {
        if (!role.points) {
          if (!result.some((r) => !r)) {
            result.push(role.points);
          } else {
            result.push(null);
          }
        } else {
          result.push(role.points);
        }
      });
      return result;
    },
    toggleAllShow() {
      if (this.listsIsOpen) {
        this.show1 = [];
        this.show2 = [];
      } else {
        this.show1 = this.characteristics1.map((k, i) => i);
        this.show2 = this.characteristics2.map((k, i) => i);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.role-results {
  background: var(--color-bg-light);
  border-radius: 0.25rem;
  border: 1px solid $color-blue;
  padding: 1.5rem;
  @media (min-width: $break-point-md) {
    padding: 2rem;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-light-gray;
    padding: .75rem 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
}
</style>
