<template>
    <div class="scale-10-wrapper">
        <div class="scale-10 scale-names">
            <div class="scale-name1 border-right" :class='getTitleClass(0)'>{{ scales[0] }}</div>
            <div class="scale-name2 border-right" :class='getTitleClass(1)'>{{ scales[1] }}</div>
            <div class="scale-name3" :class='getTitleClass(2)'>{{ scales[2] }}</div>
        </div>
        <div class="scale-10">
            <div v-for="block in 10" :key="block" class="item-block" :class="getBlockClass(block)">
                <span v-if="block==valueNorm" class="item-value">{{valueNorm}}</span>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */

export default {
    props: {
        value: Number,
        scales: {
            type: Array,
            default() {
                return ["Низкий", "Средний", "Высокий"]; 
                // return ["Низкоразвитая", "Среднеразвитая", "Высокоразвитая"]; 
            }
        }
    },
    components: {
    },
    data() {
        return {
        };
    },
    computed: {
        valueNorm() {
            let v = Math.round(this.value)
            if (v < 0) v = 0;
            if (v > 10) v = 10;
            return v
        },
    },
    methods: {
        getBlockClass(indBlock) {
            if (indBlock<=3) {
                if (this.valueNorm <=3 ) return indBlock <= this.valueNorm ? 'item-block-red' : 'item-block-red-light'
                if (this.valueNorm <=7 ) return 'item-block-yellow'
                return 'item-block-green'
            }
            if (indBlock<=7) {
                if (this.valueNorm <=7 ) return indBlock <= this.valueNorm ? 'item-block-yellow' : 'item-block-yellow-light'
                return 'item-block-green'
            }
            if (indBlock<=10) return indBlock <= this.valueNorm ? 'item-block-green' : 'item-block-green-light'
            return 'item-block'
        },
        getTitleClass(indTitle) {
            if (indTitle === 0) return this.valueNorm <=3 ? 'bold' : ''
            if (indTitle === 1) return this.valueNorm > 3 && this.valueNorm <=7 ? 'bold' : ''
            if (indTitle === 2) return this.valueNorm > 7 && this.valueNorm <=10 ? 'bold' : ''
            return ''
        },
    },
};
</script>


<style scoped lang="scss">
@import "src/assets/styles/variables";

.scale-10-wrapper {
    width: fit-content;
}

.scale-10 {
    display: flex;
    width: 100%;
}

.scale-10 .item-block {
    background: #D3EDFF;
    width: 40px;
    height: 40px;
    border: 1px solid #39A9DC;
    border-radius: 5px;
    margin-right: 1px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.scale-10 .item-block-red {
    background: #E53E3E;
    border-color: #E53E3E;
}
.scale-10 .item-block-red-light {
    background: #FCD0D0;
    border-color: #E53E3E;
}
.scale-10 .item-block-yellow {
    background: #F2C94C;
    border-color: #F2C94C;
}
.scale-10 .item-block-yellow-light {
    background: #FFF8E5;
    border-color: #F2C94C;
}
.scale-10 .item-block-green {
    background: #6FCF97;
    border-color: #6FCF97;
}
.scale-10 .item-block-green-light {
    background: #E8F8EF;
    border-color: #6FCF97;
}

.scale-10 .item-value {
    font-size: 16px;
    font-weight: 400;
}

.scale-10 .scale-name1,
.scale-10 .scale-name3 {
    flex: 0 0 30%;
    text-align: center;
    padding-top: 15px;
}

.scale-10 .scale-name2 {
    padding-top: 15px;
    flex: 0 0 40%;
    text-align: center;
}

.scale-10.scale-names {
    font-size: 12px;
    padding-bottom: 6px;
    font-weight: 400;
    /* color: #333333; */
}

.scale-10.scale-names .bold {
    font-weight: 600;
}

.border-right {
    border-right: 1px solid #DEE1EA;
}

@media screen and (max-width: 800px) {
    .scale-10 .item-block {
        width: 28px;
        height: 30px;
    }
    .scale-10 .item-value {
        font-size: 14px;
    }
    .scale-10.scale-names {
        font-size: 10px;
        margin-bottom: 4px;
    }
}

@media screen and (max-width: 600px) {
    .scale-10 .item-block {
        width: 20px;
        height: 18px;
    }
    .scale-10 .item-value {
        font-size: 11px;
    }
    .scale-10.scale-names {
        font-size: 7.5px;
        margin-bottom: 3px;
    }
}


</style>
