<template>
  <div class="scale__container">
    <div class="scale mb-6">
      <div v-if="data.title" class="scale__title mr-10">{{data.title}}</div>

      <div class="scale__values-names mr-10">
        <span v-if="data.titleStart">{{data.titleStart}}</span>
        <span v-if="data.titleEnd" class="text-right">{{data.titleEnd}}</span>
      </div>

      <div class="d-flex">
        <div class="scale__line" :class="{color}">
          <div class="scale__line-progress" ref="progress"></div>
        </div>
        <div class="scale__number font-weight-600 ml-auto" :class="colorValue">
          {{data.value}}
        </div>

      </div>

      <div v-if="data.description" class="row mr-10">
        <div class="scale__description text-dark-gray col-sm-8 mt-2">
          {{data.description}}
        </div>
      </div>

    </div>
    <div class="scale__video">
      <div v-if="data.link_to_video && $store.getters['auth/isAllowed']('view_mmpiscaledescription_video')">
        <v-btn _icon outlined color="primary" class="ml-3 mt-4" _x-small @click="showVideo=true">
          <v-icon color="primary" class="mr-2">mdi-play-circle-outline</v-icon> Смотреть видео
          </v-btn>
      </div>
    </div>
    <ShowVideo v-model="showVideo" :link="data.link_to_video"></ShowVideo>
  </div>
</template>

<script>
import ShowVideo from '@/components/ui/ShowVideo';

export default {
  name: 'Scale',
  components: {
    ShowVideo
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    color: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showVideo: false
    };
  },
  computed: {
    colorValue() {
      if (this.color) {
        return this.data.value >= 50 ? 'success--text' : 'error--text';
      }
      return '';
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.progress.style.width = `${100 - this.data.value}%`;
    }, 0);
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";

.scale {
  flex-grow: 1;

  &__container {
    display: flex;
  }
  &__video {
    flex: 240px 0 0;
  }
  &__title {
    font-size: $font-sm;
    font-weight: 600;
    text-align: center;
    //@media (min-width: $break-point-sm) {
    //  font-size: $font-sm;
    //}
  }

  &__values-names {
    font-size: $font-xxs;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    & span {
      max-width: 50%;
    }
    @media (min-width: $break-point-xxs) {
      font-size: $font-xs;
    }
    @media (min-width: $break-point-sm) {
      font-size: $font-sm;
    }
  }

  &__line {
    position: relative;
    width: calc(100% - 40px);
    height: 6px;
    border-radius: 4px;
    color: $color-light-gray;
    margin-top: 10px;
    background: linear-gradient(90deg, var(--primary-color-middle) 0%, var(--v-primary-base) 100%);
    &.color {
      background: linear-gradient(90deg, #FF4D00 0%,
        rgba(255, 184, 0, 0.98) 27.08%,
        #FFF503 64.06%, #ADFF00 100%),
      linear-gradient(90deg, #B7F4F8 0%, var(--v-primary-base) 100%);
    }
    &:before {
      content: '';
      width: 2px;
      height: 10px;
      position: absolute;
      left: 50%;
      top: -10px;
      transform: translateX(-1px);
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      background: currentColor;
    }
    &-progress {
      transition: 1s width;
      width: 100%;
      height: 100%;
      border-radius: 0 2px 2px 0;
      margin-left: auto;
      background: currentColor;
    }
  }
  &__number {
    color: $color-blue;
    font-size: $font-sm;
    @media (min-width: $break-point-sm) {
      font-size: $font-default;
    }
  }

  &__description {
    font-size: $font-xs;
  }
}

// .platform-ta .progress-bar__linear--blue {
//   background: linear-gradient(90deg, #D8FFDC, var(--v-primary-base));
// }

</style>
