<template>
  <div class="setup-compliance-card">
    <v-dialog v-model="addDialog" max-width="390">
      <v-card>
        <v-btn class="button-close ma-4" icon @click="addDialog=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title />
        <v-card-text>
          Добавить профессиограмму или раздел
        </v-card-text>

        <v-treeview open-all return-object dense :items="chapters">
          <!-- <template v-slot:prepend="{ item }">
              <v-checkbox dense class="ma-0" hide-details
                    value
                      :indeterminate="item.children"
             ></v-checkbox>
            <v-icon>
            </v-icon>
          </template> -->
          <template v-slot:label="{ item }">
                <div :class="canAdd(item) ? 'item-normal' : 'item-disabled'" @click="addItem(item)">
                  {{ item.name }}
                </div>
          </template>
        </v-treeview>
      </v-card>
    </v-dialog>

    <h4 class="text-center">Отчет соответствия</h4>
    <!-- <div class="row mt-1">
      <div class="col">
        <v-select
          v-model="report.main_professiogram" :items="sortedProfs"
          label="Основная должность:"
          item-text="name" item-value="id" hide-details :menu-props="{ maxHeight: '400' }"
        />
      </div>
    </div> -->
    <div class="row mt-1 relative mb-7">
      <div class="col mb-2">
        <div class="text-caption mb-2">
          Профессиограммы:
        </div>
        <v-progress-circular v-if="loading" :size="30" color="primary" class="text-center" indeterminate />
        <div v-else-if="(!report.professiograms || report.professiograms.length===0) && (!report.chapters || report.chapters.length===0)"
            class="text-caption my-2 grey--text"
        >
          Список пуст
        </div>
        <div v-else>
          <div v-for="p in report.professiograms" :key="p.id" >
            <v-chip class="mr-2 mb-2" close color="primary"  label text-color="primary" outlined @click:close="delProfFromReport(p.id)">
              {{p.name}}
            </v-chip>
          </div>

          <div v-for="p in report.chapters" :key="p.id" >
            <v-chip class="mr-2 mb-2" close color="blue lighten-5" text-color="primary" label @click:close="delChapterFromReport(p.id)">
              {{p.name}}
            </v-chip>
          </div>
        </div>

        <v-btn v-if="!loading" class="mx-0 " fab dark small absolute bottom left color="primary" @click="addDialog=true">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>

      </div>

    </div>

    <!-- <v-divider class="mt-1 mb-5" />
    <v-select  item-value="id" v-model="report.method" :items="calcTypes" label="Метод расчета" _outlined dense hide-details /> -->

    <div class="row mt-1">
      <div class="col">
        <v-btn class="w-100 text-center" color="primary" :disabled="!canCalc()" :loading="calculating" @click="calc()">
          <!-- {{ this.$store.getters['users/candidate/candidate'].testing_status === 'COMPLETED' ? 'Сохранить и рассчитать' : 'Сохранить' }} -->
          Рассчитать
        </v-btn>
      </div>
    </div>
    <div class="row mt-1" v-if="$store.getters['auth/isAllowed']('delete_reportsetup')">
      <div class="col">
        <v-btn class="w-100" color="error" outlined :loading="deletingReport" @click="showAskDialog=true">
          <v-icon color="error">mdi-delete</v-icon> Удалить
        </v-btn>
      </div>
    </div>
    <AskDialog v-model="showAskDialog" @ok="delReportSetup()" />

  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AskDialog from "@/components/ui/AskDialog"

export default {
  name: 'SetupCompliance',
  components: {
    AskDialog
  },
  data: () => ({
    addDialog: false,
    calcTypes: [{ id: 'peaks', text: "По пикам" }, { id: 'ranges', text: "По диапазонам" }],
    showAskDialog: false,
  }),
  computed: {
    ...mapGetters({
      candidate: 'users/candidate/candidate',
      profs: 'profile/report/profs',
      chapters: 'profile/report/chapters',
      report: 'profile/report/complianceSetup',
      calculating: 'profile/report/calculating',
      loading: 'profile/report/loading',
      deletingReport: 'profile/report/deletingReport',
      isChapterAdded: 'profile/report/isChapterAdded',
      isProfAdded: 'profile/report/isProfAdded',
      calcError: 'profile/report/calcError',
    }),
    sortedProfs() {
      return this.profs.sort( (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()) )
    }
  },
  methods: {
    ...mapActions({
      calcCompliance: 'profile/report/calcCompliance',
      delReportSetup: 'profile/report/delReportSetup',
    }),
    ...mapMutations({
      delProfFromReport: 'profile/report/delProfFromReport',
      delChapterFromReport: 'profile/report/delChapterFromReport',
      addProfToReport: 'profile/report/addProfToReport',
      addChapterToReport: 'profile/report/addChapterToReport',
    }),
    search() {
    },
    canCalc() {
      // console.log(this.$store.getters['users/candidate/candidate']);
      return (this.report.professiograms && this.report.professiograms.length) || (this.report.chapters && this.report.chapters.length)

    },
    canAdd(item) {
      return item.children ? !this.isChapterAdded(item.id) : (!this.isProfAdded(item.id) && this.report.main_professiogram !== item.id)
    },
    addItem(item) {
      if (!this.canAdd(item))
        return
      if (item.children) {
        this.addChapterToReport(item)
      } else {
        this.addProfToReport(item)
      }
      this.addDialog = false
    },
    async calc() {
      const result = await this.calcCompliance({
        idCanidate: this.$route.params.id,
        saveOnly: false
        // this.$store.getters['users/candidate/candidate'].testing_status !== 'COMPLETED'
      });

      if (result && result.compliances) {
        let str = ""
        for (const res of result.compliances) {
          if (res.error && res.error.length) {
            for (const err of res.error) {
              str += '&bull;&nbsp;<b>' + err.name + '</b>:' + err.error_messages.join(" ")+"<br>"
            }
          }
        }  
        if (str)  
          this.$toast.error('Ошибка при расчете:<br>' + str, '', { position: 'topRight', timeout: 0})

      } else
        this.$toast.error('Ошибка при расчете' + (this.calcError ? (':<br><b>'+this.calcError+'</b>') : ''), '', {position: 'topRight'})

    },
  },
  async mounted() {
    await this.$store.dispatch('profile/report/loadComplianceSetup', {candidate: this.candidate});
  }
};
</script>
<style scoped lang="scss">
@import "src/assets/styles/variables";

.setup-compliance-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 16px;
}

.item-normal {
    cursor: pointer;
}

.item-disabled {
    color: #888;
}

.v-chip {
  white-space: normal;
  height: auto !important;
  min-height: 32px;
}

.v-chip .v-chip__content {
  height: auto;
  word-break: break-word;
}

</style>


