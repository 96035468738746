import { render, staticRenderFns } from "./Scale10ColorBlocks.vue?vue&type=template&id=1d02cc70&scoped=true"
import script from "./Scale10ColorBlocks.vue?vue&type=script&lang=js"
export * from "./Scale10ColorBlocks.vue?vue&type=script&lang=js"
import style0 from "./Scale10ColorBlocks.vue?vue&type=style&index=0&id=1d02cc70&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d02cc70",
  null
  
)

export default component.exports